<template>
  <div class="upload">
    <div class="main-container">
      <h1>Upload Document</h1>
    </div>
    <hr>
    <div class="main-container">
      <upload-list />
    </div>
  </div>
</template>

<script>
import UploadList from '../components/uploads/UploadList.vue';

export default {
  name: 'Upload',
  components: {
    UploadList,
  },
};
</script>
