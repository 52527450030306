<template>
  <div>
    <file-pond
      ref="pond"
      name="s3-upload"
      label-idle="Drag and drop files here, or click to browse..."
      :allow-multiple="true"
      :accepted-file-types="[
        'application/pdf',
        'application/msword', // .doc
        'application/vnd.openxmlformats-officedocument.wordprocessingml.document', // .docx
        'application/vnd.ms-powerpoint', // .ppt
        'application/vnd.openxmlformats-officedocument.presentationml.presentation', // .pptx
        'application/vnd.ms-excel', // .xls
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', // .xlsx
        'image/bmp',
        'image/jpeg',
        'image/png',
        'image/tiff',
      ]"
      :file-validate-type-detect-type="customTypeValidation"
    />
  </div>
</template>
<script>
import { mapActions } from 'vuex';
import vueFilePond, { setOptions } from 'vue-filepond';

import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type/dist/filepond-plugin-file-validate-type.esm';
import 'filepond/dist/filepond.min.css';
import { useToast } from 'vue-toastification';
import isSet from '@/store/helpers/isSet';

const FilePond = vueFilePond(FilePondPluginFileValidateType);

export default {
  components: {
    FilePond,
  },
  props: {
    postVars: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      toast: useToast(),
    };
  },
  mounted() {
    this.setFilepondOptions();
  },
  methods: {
    ...mapActions({
      postDocumentRequest: 'documentStages/postDocumentRequest',
      addNewUploadedDocument: 'processingDocuments/addNewUploadedDocument',
    }),
    customTypeValidation(source, type) {
      this.$log.info('custom type validation');
      this.$log.info(source, type);
      return new Promise((resolve, reject) => {
        if (source && source.name && this.filenameValidation(source.name)) {
          resolve(type);
        } else {
          reject(Error('Invalid file type'));
        }
      });
    },
    filenameValidation(filename) {
      if (!filename) {
        return false;
      }
      if (!filename.length > 4) {
        return false;
      }
      if (filename.toLowerCase().indexOf('.') === -1) {
        return false;
      }
      return true;
    },
    setFilepondOptions() {
      setOptions({
        server: {
          process: async (
            fieldName,
            file,
            metadata,
            load,
            error,
            progress,
            abort,
            transfer,
            options,
          ) => {
            this.$log.info(
              fieldName,
              file,
              metadata,
              load,
              error,
              progress,
              abort,
              transfer,
              options,
            );
            const postVars = {
              ...this.postVars,
              fileName: file.name,
            };
            if (!('docType' in postVars) || !postVars.docType) {
              this.toast.error('Document type not specified');
              error('Invalid upload request');
            }

            // Make Post Request:
            const post = await this.postDocumentRequest({ file, postVars });
            let documentRequestId = -1;
            post.req
              .then((r) => {
                load(r);
                this.$log.info('file uploaded successfully:', r);
                documentRequestId = r.documentRequestId;
                this.addNewUploadedDocument();
                if (!documentRequestId) {
                  throw Error(
                    'Document request Id not extracted from response',
                  );
                }
              })
              .catch((e) => {
                if (e.response && isSet(e.response.data.errors)) {
                  this.toast.error(e.response.data.errors);
                } else {
                  this.toast.error(
                    `Something went wrong with upload of file: ${file.name}`,
                  );
                }
                error('Something went wrong with upload');
              });

            return {
              abort: () => {
                // Abort not yet working with first pre-signed url request.
                // Need to update so that cancel() returned before internal await
                this.$log.info('aborting...');
                if (post.cancelFunc) {
                  post.cancelFunc(); // function is entered if the user has tapped the cancel button
                }
                abort(); // Let FilePond know the request has been cancelled
              },
            };
          },
        },
      });
    },
  },
};
</script>
<style lang="scss">
.filepond--credits {
  display: none;
}

.filepond--item {
  width: calc(50% - 0.5em);
}

/* bordered drop area */
.filepond--panel-root {
  background-color: transparent;
  border: 2px dashed $bg-nav;
}
</style>
