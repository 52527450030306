<template>
  <p
    v-if="limitedUploads"
    class="error-area"
  >
    This account is currently operating as a trial, there is a limit of 10 documents that may exist in the platform at any time.
  </p>
  <ul class="upload-list">
    <li class="upload-item">
      <div class="num">
        <span>1</span>
      </div>
      <div class="content">
        <h3>Select Document Type</h3>
        <select-input
          :key="selectPlaceholder"
          v-model="documentType"
          select-id="document-selector"
          :select-placeholder="selectPlaceholder"
          name="document types"
          :options="documentTypesToDisplayMapping"
        />
      </div>
    </li>

    <li class="upload-item">
      <div class="num">
        <span>2</span>
      </div>
      <upload-area
        :upload-area-state="uploadListState"
        :show-prepared-documents="showPreparedDocuments"
        :document-type="documentType"
      />
    </li>

    <li
      class="upload-item"
    >
      <div class="num">
        <span>3</span>
      </div>
      <div class="content">
        <div class="content-flex">
          <h3 style="margin-right: 4px">
            Extract Data
          </h3>
          <span
            v-tooltip="'Refresh documents'"
            class="icon-container"
            @click.prevent="triggerResetTimer"
          >
            <rotate-cw-icon
              size="1.3x"
              :stroke="'black'"
            />
          </span>
        </div>
        <document-intake-progress
          ref="documentIntakeProgressRef"
        />
      </div>
    </li>
  </ul>
</template>
<script>
import { mapActions, mapGetters } from 'vuex';
import { useToast } from 'vue-toastification';
import isSet from '@/store/helpers/isSet';
import UploadArea from '@/components/uploads/UploadArea.vue';
import { RotateCwIcon } from '@zhuowenli/vue-feather-icons';
import DocumentIntakeProgress from './DocumentIntakeProgress.vue';
import SelectInput from '../forms/SelectInput.vue';
import { allowedStates } from '../../store/helpers/storeState';

export default {
  components: {
    UploadArea,
    DocumentIntakeProgress,
    SelectInput,
    RotateCwIcon,
  },
  data: () => ({
    documentType: '',
    toast: useToast(),
    uploadListState: allowedStates.IS_LOADING,
  }),
  computed: {
    ...mapGetters({
      documentTypesStoreStatus: 'documentTypes/storeStatus',
      documentTypesToDisplayMapping: 'documentTypes/documentTypesToDisplayMapping',
      isTrial: 'localisation/isTrial',
      limitedUploads: 'localisation/limitedUploads',
    }),
    selectPlaceholder() {
      return this.selectIsReady ? 'Select document type' : 'Loading available document types...';
    },
    selectIsReady() {
      return this.documentTypesStoreStatus === allowedStates.IS_READY;
    },
    showPreparedDocuments() {
      const preparedQueryParam = this.$route.query.prepared;
      if (isSet(preparedQueryParam)) {
        return preparedQueryParam === 'true';
      }

      return this.isTrial;
    },
    allowedStates() {
      return allowedStates;
    },
  },
  mounted() {
    const promises = [this.getDocumentTypes(), this.getSettings()];
    this.uploadListState = allowedStates.IS_LOADING;
    Promise.all(promises)
      .then(() => {
        const documentTypeKeys = Object.keys(this.documentTypesToDisplayMapping);
        if (documentTypeKeys.length === 1) {
          [this.documentType] = documentTypeKeys;
        }
        this.uploadListState = allowedStates.IS_READY;
      })
      .catch((e) => {
        this.$log.error(e);
        this.toast.error('Error fetching document types');
        this.uploadListState = allowedStates.IS_ERRORING;
      });
  },
  methods: {
    ...mapActions({
      getDocumentTypes: 'documentTypes/lazyGet',
      getSettings: 'localisation/lazyInit',
    }),
    triggerResetTimer() {
      this.$refs.documentIntakeProgressRef.resetTimer();
    },
  },
};
</script>
