<template>
  <div>
    <div class="document-upload-info-container">
      <p class="document-upload-info text-primary-freyda">
        {{ docsInProcessing.length }} {{ docsInProcessing.length === 1 ? 'document is ' : 'documents are ' }} processing.
        It usually takes about 3-5 minutes for each document to be processed.
      </p>
    </div>

    <transition-group
      name="progress-stack-list"
      class="progress-stack-list"
      tag="ul"
    >
      <li
        v-for="doc in docsInProcessing"
        :key="doc.id"
        class="progress-stack-list-item"
      >
        <div class="progress-stack">
          <!--FIlename -->
          <div
            v-tooltip="`${doc.filename}`"
            class="progress-stack-item upload-progress-filename"
          >
            <b> {{ doc.filename }} </b>
          </div>
          <!-- Progress stage -->
          <div class="progress-stack-item upload-progress-stage">
            {{ getDocumentProcessName(doc.stage) }}
            <span v-if="doc.stage === 'EXT_MANUAL_QA'">
              <info-icon
                v-tooltip="`This extraction is below the desired confidence threshold
                and has been sent for review. Document will be available within one business day.`"
                size="1x"
              />
            </span>
          </div>
          <!-- Progress bar -->
          <div
            v-if="docStageIsNotFailed(doc.stage)"
            class="progress-stack-item progress-bar-and-percentage"
          >
            <div
              class="upload-progress-bar progress-stack-item"
            >
              <progress-bar
                :value="docStageToNumericPercentage(doc.stage)"
                :total-value="100"
                class="upload-progress-bar"
              />
            </div>
            <div
              class="progress-stack-item"
            >
              {{ docStageToNumericPercentage(doc.stage) }}%
            </div>
          </div>
          <!-- Alternatively, a error-->
          <div
            v-else
            class="error-area error-nowrap error-center"
          >
            {{ getExtractionStageDisplay(doc.stage) }}
          </div>
          <!-- DOcument actions -->
          <div class="progress-stack-item document-actions">
            <div
              class="icon-container"
              @click="deleteDocument(doc.documentRequestId)"
            >
              <trash2-icon size="1.5x" />
            </div>
            <div
              v-if="!docStageIsNotFailed(doc.stage)"
              v-tooltip="'Retry document'"
              class="icon-container"
              @click="retryDocument(doc.documentRequestId, doc.documentTypeName)"
            >
              <refresh-cw-icon
                size="1.5x"
                :class="{'icon-rotate': isDocInRequeue(doc.documentRequestId)}"
                :stroke="isDocInRequeue(doc.documentRequestId) ? '#5e5c5c' : 'black'"
              />
            </div>
            <div
              v-if="!docStageIsNotFailed(doc.stage)"
              class="icon-container"
            >
              <download-icon
                size="1.5x"
                @click="downloadDocument(doc.documentRequestId, doc.filename)"
              />
            </div>
          </div>
        </div>
      </li>
    </transition-group>
  </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex';
import {
  RefreshCwIcon, InfoIcon, Trash2Icon, DownloadIcon,
} from '@zhuowenli/vue-feather-icons';
import { useToast } from 'vue-toastification';
import { errorMessages } from '@/store/helpers/display/toastMessages';
import { saveFile } from '@/store/helpers/fileDownloaders';
import ProgressBar from '../general/ProgressBar.vue';
import { allowedStates } from '../../store/helpers/storeState';
import { extractionProgressMapping } from '../../store/helpers/mapping/extractionProgresses';

export default {
  components: {
    ProgressBar, Trash2Icon, RefreshCwIcon, InfoIcon, DownloadIcon,
  },
  data: () => ({
    toast: useToast(),
    intervalId: null,
    activeRequeueDocs: [],
  }),
  computed: {
    ...mapGetters({
      docUploadedCount: 'processingDocuments/docUploadedCount',
      processingDocumentsStoreStatus: 'processingDocuments/storeStatus',
      docsInProcessing: 'processingDocuments/documents',
    }),
    hasDocsInProcessing() {
      const filteredDocs = this.docsInProcessing.filter(
        (doc) => this.docStageIsNotFailed(doc.stage) && doc.stage !== 'EXT_MANUAL_QA',
      );
      return filteredDocs.length !== 0;
    },
  },
  watch: {
    docUploadedCount() {
      this.$log.debug('docUploadedCount increased, resetting refresh timer');
      this.resetTimer();
    },
  },
  mounted() {
    this.processingDocumentsInit();
    this.getDocsInProcessing();
    this.startRefreshing();
  },
  beforeUnmount() {
    this.stopRefreshing();
  },
  methods: {
    ...mapActions({
      deleteDocumentRequests: 'documents/deleteDocumentRequests',
      getDocumentRequestsInProcessing: 'processingDocuments/getDocumentRequestsInProcessing',
      getDocumentLinks: 'documents/getDocumentLinks',
      processingDocumentsInit: 'processingDocuments/init',
      requeueDocuments: 'documents/requeueDocuments',
    }),
    async deleteDocument(id) {
      this.$log.info('deleting document:', id);
      await (this.deleteDocumentRequests([id])
        .then(() => this.toast.success('Successfully deleted document'))
        .catch(() => this.toast.error('Request to delete document was unsuccessful'))
      );
      this.resetTimer();
    },
    async retryDocument(id, documentType) {
      this.$log.info('Retry document:', id, documentType);

      // If document is already in requeue, do not add it to requeue again.
      if (this.isDocInRequeue(id)) {
        this.$log.info('Document is already attempted to be requeued..');
        return;
      }

      // Send request.
      this.activeRequeueDocs.push(id);
      await (this.requeueDocuments([{ document_request_id: id, document_type: documentType }])
        .then(() => this.toast.success('Document requeued for extraction'))
        .catch(() => this.toast.error('Request to requeue document was unsuccessful'))
      );
      this.resetTimer();

      // Remove doc from active requeue list.
      const index = this.activeRequeueDocs.indexOf(id);
      if (index !== -1) {
        this.activeRequeueDocs.splice(index, 1);
      }
    },
    /**
     * Refresh the documents only if there is at least one document
     * being processed. Otherwise stop refreshing.
     */
    refresh() {
      this.$log.debug('Auto refreshing; hasDocsInProcessing:', this.hasDocsInProcessing);
      if (this.hasDocsInProcessing) {
        this.getDocsInProcessing();
      } else {
        this.stopRefreshing();
      }
    },
    startRefreshing() {
      this.intervalId = setInterval(this.refresh, 15000); // Auto-refresh after 15s.
    },
    stopRefreshing() {
      clearInterval(this.intervalId);
    },
    /**
     * Refresh documents in processing and reset timer.
     * e.g if the time it takes to refresh automatically is 10s and
     *     8s have gone, when the user clicks the refresh icon, we
     *     won't make another automatic request in 2s, instead we
     *     restart the 10s timer.
     */
    resetTimer() {
      this.stopRefreshing();
      this.getDocsInProcessing();
      this.startRefreshing();
    },
    getDocsInProcessing() {
      if (this.processingDocumentsStoreStatus !== allowedStates.IS_READY) {
        this.toast.info('Waiting for document progress');

        return;
      }
      this.$log.info('Getting doc reqs in stages:');
      this.getDocumentRequestsInProcessing()
        .then((docs) => {
          this.$log.info('fetched and transformed docs:', docs);
          // this.toast.success('Updated document progress');
        })
        .catch((e) => {
          this.$log.error(e);
          this.toast.error('Error updating document progress');
        });
    },
    docStageToNumericPercentage(stage) {
      return extractionProgressMapping[stage].progress || 1;
    },
    docStageIsNotFailed(stage) {
      return ![
        'EXT_FAILED',
        'EXT_FAILED_DUPLICATE',
        'EXT_FAILED_INVALID_DOCUMENT',
        'EXT_FAILED_PASSWORD',
        'EXT_FAILED_UNSUPPORTED_TYPE',
      ].includes(stage);
    },
    getDocumentProcessName(stage) {
      if (!this.docStageIsNotFailed(stage)) {
        return '';
      }
      if (stage === 'EXT_MANUAL_QA') {
        return 'Quality assurance';
      }
      return 'Processing...';
    },
    getExtractionStageDisplay(stage) {
      return extractionProgressMapping[stage].display;
    },
    isDocInRequeue(id) {
      return this.activeRequeueDocs.indexOf(id) !== -1;
    },
    downloadDocument(docId, fileName) {
      this.getDocumentLinks({ docId, fileName }).then((filepaths) => {
        saveFile(fileName, filepaths.pdf);
      }).catch((err) => {
        this.toast.error(errorMessages.FAILED_DOWNLOAD_DOCUMENT);
        this.$log.error('Error while getting document links: ', err);
      });
    },
  },
};
</script>
